import { ThemeOptions } from "@mui/material";

export const palette: ThemeOptions["palette"] = {
  primary: {
    main: "#6270c1",
    contrastText: "#fff",
  },
  secondary: {
    main: "#1b1e3d",
    contrastText: "#fff",
  },
  common: {
    black: "#000",
    white: "#fff",
  },
  error: {
    main: "#d86b61",
    contrastText: "#fff",
  },
  success: {
    main: "#5cb85c",
    contrastText: "#fff",
  },
  warning: {
    main: "#f0ad4e",
    contrastText: "#fff",
  },
  info: {
    main: "#5bc0de",
    contrastText: "#fff",
  },
  action: {
    hover: "#ebebf0",
  },
};
