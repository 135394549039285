"use client";
import React from "react";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import PostHogPageView from "./posthog-pageview-provider";

if (
  typeof window !== "undefined" &&
  posthog &&
  process.env.NEXT_PUBLIC_VERCEL_ENV == "production"
) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: false,
    autocapture: false,
  });
}

export const PosthogProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV! !== "production") {
    return <>{children}</>;
  }

  return (
    <PostHogProvider client={posthog}>
      <PostHogPageView />
      {children}
    </PostHogProvider>
  );
};
