import { ThemeOptions } from "@mui/material";

export const typography: ThemeOptions["typography"] = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  h1: {
    fontSize: "3rem",
    fontWeight: "bold",
    lineHeight: "3rem",
    letterSpacing: "-0.02em",
    color: "#1b1e3d",
  },
  h2: {
    fontSize: "2.5rem",
    fontWeight: 600,
    color: "#1b1e3d",
  },
  h3: {
    fontSize: "2rem",
    fontWeight: 600,
    color: "#1b1e3d",
  },
  h4: {
    fontSize: "1.75rem",
    fontWeight: 600,
    color: "#1b1e3d",
  },
  h5: {
    fontSize: "1.25rem",
    fontWeight: 600,
    color: "#1b1e3d",
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#1b1e3d",
  },
  subtitle1: {
    margin: "8px 0 0",
    opacity: 0.8,
    fontSize: "16px",
    fontWeight: 300,
    color: "#1b1e3d",
  },
  subtitle2: {
    margin: "8px 0 0",
    opacity: 0.8,
    fontSize: "14px",
    fontWeight: 300,
    color: "#1b1e3d",
  },
  body1: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#1b1e3d",
  },
  body2: {
    fontSize: "13px",
    fontWeight: 400,
    color: "#6a718e",
  },
  button: {
    fontSize: "14px",
    fontWeight: 500,
    color: "common.white",
  },
};
